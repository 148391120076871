<template>
    <div class="p1" id="a3">
        <div class="header-search">
            <div class="title">
                <el-row>
                    <el-col :span="12">
                        <span class="line"></span>
                        分润明细表
                    </el-col>
                    <el-col :span="12">
                        <div class="flex align-center justify-end h50">
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="header-input">
            <div class="model-mask">
                <div
                        class="header-search-box flex align-center justify-end mt20"
                >
                    <el-form :inline="true">
                        <el-form-item>
                            <el-input
                                    placeholder="下级员工|AD|汇报范围|上级员工|AD|汇报范围 "
                                    prefix-icon="el-icon-search"
                            >
                                <el-button
                                        slot="append"
                                        icon="el-icon-search"
                                ></el-button>
                            </el-input>
                        </el-form-item>
                        <!-- <el-button
                                type="primary"
                                size="small"
                                plain
                                class="is-circle"
                        ><i class="el-icon-plus fw900 f16"></i
                            ></el-button> -->
                    </el-form>
                </div>


                <!-- 表格 -->
                <!-- 用户列表区域 -->
                <el-table
                        :data="gridData"
                        border
                        fit
                        height="240px"
                        highlight-current-row
                        style="width: 100%"
                >
                    <el-table-column label="用户头像" align="center">
                        <template slot-scope="scope">
                            <img :src="scope.row.memberLogo" alt="" style="width:50%">
                        </template>
                    </el-table-column>
                    <el-table-column label="用户名称" prop="memberName" sortable="custom" align="center">
                        <template slot-scope="scope">
                            <span>{{ decodeURI(scope.row.memberName) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="设备所在位置"
                            prop="deviceAddress"
                            sortable="custom"
                            align="center"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.deviceAddress }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="手机号"
                            prop="phone"
                            sortable="custom"
                            align="center"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.phone }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="运营商分红"
                            prop="operatorFee"
                            sortable="custom"
                            align="center"
                    >
                        <template slot-scope="scope">
            <span>{{scope.row.operatorFee}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="创建时间"
                            prop="createDt"
                            sortable="custom"
                            align="center"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.createDt">{{ scope.row.createDt | dataFormat}}</span>
                            <span v-else>---</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                            label="操作"
                            align="center"
                            class-name="small-padding fixed-width"
                    >
                        <template slot-scope="scope">
                            <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="删除"
                                    placement="bottom"
                            >
                                <i
                                        class="el-icon-delete textblue f16 ml20"
                                        @click="del(scope.row.id)"
                                ></i>
                            </el-tooltip>
                            <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="重置密码"
                                    placement="bottom"
                            >
                                <i class="el-icon-lock textblue f16 ml20" @click="reset(1)"></i>
                            </el-tooltip>
                        </template>
                    </el-table-column> -->
                </el-table>
                <div class="footerBox">
            <!-- 分页区域 -->
            <!-- <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="queryInfo.currPage"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            ></el-pagination> -->
          </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
    operatorCode: String
    },
    data() {
        return {
      tableData: [],
      gridData: [],
      total: 0,
      searchInput: "",
      
      queryInfo: {
        // 当前页数
        currPage: 1,
        
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          administratorName: "",
          operatorCode: "",
        },
      },
    };
    },
    created() {
        this.queryInfo.condition.operatorCode=this.operatorCode;
        
    },
    mounted() {
        this.loadEleFence();
    },
    // watch:{
    //   operatorCode(){
    //     this.loadEleFence();
    //   }
    // },
    methods: {
        search() {
      this.loadEleFence();
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.loadEleFence();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.loadEleFence();
    },
    sortChange() {},
    loadEleFence() {
      var that = this;
      that.queryInfo.condition.administratorName = that.searchInput;
      console.log(that.operatorCode);
      that.queryInfo.condition.operatorCode = that.operatorCode;
      if (that.operatorCode) {
          that.$http
        // .post("/electronicFence/list", {
        .post("/userOperator/list2", that.queryInfo)
        .then(function (response) {
          if (response.data.code == 200) {
            // console.log("---------");
            console.log(response.data.data)
            that.total = response.data.data.data[0].memberOrderList.lenght;
            that.gridData = response.data.data.data[0].memberOrderList
            // debugger
            console.log(response.data.data.data[0].memberOrderList);
          }
        });
      }
    },
    }

}
</script>
