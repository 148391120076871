<template>
  <div class="p1" id="a3">
    <div class="header-search">
      <div class="title">
        <el-row>
          <el-col :span="12">
            <span class="line"></span>
            管辖电子围栏
          </el-col>
          <el-col :span="12">
            <div class="flex align-center justify-end h50"></div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="header-input">
      <div class="model-mask">
        <div class="header-search-box flex align-center justify-end mt20">
          <el-form :inline="true">
            <el-form-item>
              <el-input
                style="width: 250px"
                placeholder="请输入管理员名称"
                clearable
                v-model="searchInput"
                class="header-search-input ml10"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  class="textblue searchBtn"
                  @click="search()"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <!-- 表格 -->
        <!-- 用户列表区域 -->
        <div>
          <el-table
            :data="gridData"
            border
            fit
            highlight-current-row
            style="width: 100%"
            @sort-change="sortChange"
          >
            <el-table-column label="编号" type="index" width="50">
            </el-table-column>
            <el-table-column label="省市区" align="center" prop="area">
              <template slot-scope="scope">
                <span>{{ scope.row.area }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="电子围栏名称"
              align="center"
              prop="electronicFenceName"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.electronicFenceName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="所属管理员" align="center" prop="">
              <template slot-scope="scope">
                <span>{{ scope.row.administratorName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="" align="center" prop="id" v-if="false">
              <template slot-scope="scope">
                <span>{{ scope.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center" prop="">
              <template slot-scope="scope">
                <span>{{
                  scope.row.fenceStatus == 0 ? "未使用" : "已使用"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" align="center" prop="">
              <template slot-scope="scope">
                <span v-if="scope.row.createDt">{{
                  scope.row.createDt | dataFormat
                }}</span>
                <span v-else>---</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="footerBox">
            <!-- 分页区域 -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="queryInfo.currPage"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    operatorCode: String
  },
  data() {
    return {
      tableData: [],
      gridData: [],
      total: 0,
      searchInput: "",

      queryInfo: {
        // 当前页数
        currPage: 1,

        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          administratorName: "",
          operatorCode: ""
        }
      }
    };
  },
  created() {
    this.queryInfo.condition.operatorCode = this.operatorCode;
  },
  mounted() {
    this.loadEleFence();
  },
  watch: {
    operatorCode() {
      this.loadEleFence();
    }
  },
  methods: {
    search() {
      this.loadEleFence();
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.loadEleFence();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.loadEleFence();
    },
    sortChange() {},
    loadEleFence() {
      var that = this;
      that.queryInfo.condition.administratorName = that.searchInput;
      that.queryInfo.condition.operatorCode = that.operatorCode;
      if (that.operatorCode) {
        that.$http
          // .post("/electronicFence/list", {
          .post("/userOperator/list2", that.queryInfo)
          .then(function(response) {
            if (response.data.code == 200) {
              // console.log("---------");
              // console.log(response.data.data)
              that.total = response.data.data.count;
              that.gridData = response.data.data.data[0].electronicFenceList;
              // debugger
              console.log(response.data.data);
            }
          });
      }
    }
  }
};
</script>
